import { useEffect } from 'react';
let dynamicTextDomElement;
const useDynamicText = () => {
  useEffect(() => {
    dynamicTextDomElement = document.querySelector('.dynamic-text');
    const dynamicTextChange = () => {
      const updateTextAndAnimate = (text, delay) => {
        setTimeout(() => {
          dynamicTextDomElement.textContent = text;

          // Trigger reflow to restart animation
          dynamicTextDomElement.classList.remove('animate');
          void dynamicTextDomElement.offsetWidth; // Trigger reflow
          dynamicTextDomElement.classList.add('animate');
        }, delay);
      };

      updateTextAndAnimate('Passion!', 0);
      updateTextAndAnimate('Skill!', 3000);
      updateTextAndAnimate('Rewards!', 6000);
    };
    dynamicTextChange();
    const intervalId = setInterval(dynamicTextChange, 9200);

    // Clean up function to clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []); // Empty dependency array means this effect runs once after the component mounts
};

export default useDynamicText;
