import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import DeleteAccount from './pages/DeleteAccount';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import DownloadApp from './pages/DownloadApp';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route
          path="/"
          exact
          element={<App />}
        />
        <Route
          path="/deleteAccount"
          element={<DeleteAccount />}
        />
        <Route
          path="/privacyPolicy"
          element={<PrivacyPolicy />}
        />
        <Route
          path="/termsAndConditions"
          element={<TermsAndConditions />}
        />
        <Route
          path="/downloadApp"
          element={<DownloadApp />}
        />
        <Route
          path="*"
          element={<App />}
        />
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
