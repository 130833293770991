import { useState, useEffect } from 'react';
import logo from './images/FutyLogoWhite.png';
import footballWithLine from './images/FootballWithHalfLine.png';
import instagramPng from './images/instagram.png';
import tiktokPng from './images/tiktok.png';
import linkedInWhitePng from './images/linkedinWhite.png';
import tickPng from './images/tickGreen.png';
import './App.css';
import MobileView from './MobileVersion';
import useDynamicText from './hooks/useDynamicText';
import JoinNowButton from './pages/components/JoinNowButton';
import { handleJoinTelegram } from './utils/constants';

export default function Home() {
  const [contactUsFormEmail, setContactUsFormEmail] = useState('');
  const [contactUsFormName, setContactUsFormName] = useState('');
  const [contactUsFormContent, setContactUsFormContent] = useState('');
  const [isMessageSent, setIsMessageSent] = useState(false);

  const handleContactUsSubmit = async (e) => {
    e.preventDefault();
    try {
      const queryParams = new URLSearchParams(window.location.search);
      let source = queryParams.get('source')
        ? queryParams.get('source')
        : 'unknown';

      let formData = {
        email: contactUsFormEmail,
        name: contactUsFormName,
        message: contactUsFormContent,
        source: source,
      };
      // eslint-disable-next-line no-undef
      ttq.track('DownloadNow', { source: 'd' + source });
      if (!formData.email || !formData.name || !formData.message) {
        alert('email, name, reason to contact are all mandatory');
        return;
      }
      console.log(formData);
      setIsMessageSent(true);
      const url = 'https://api.futygame.uk/v0/contactUs';
      // const url = 'http://localhost:9000/v0/contactUs';

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const responseData = await response.json(); // Extract JSON data from response

      console.log(responseData); // Output the response data to console

      setTimeout(() => {
        setContactUsFormContent('');
        setContactUsFormEmail('');
        setContactUsFormName('');
        setIsMessageSent(false);
      }, 180000);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const styles = {
    dynamicText: {},
    contactUsLineBreak: {
      width: '100%',
      height: '0.1em',

      background: '#FFFFFF',
      opacity: '0.2',
    },
    commentBox: {
      color: '#fff',
      border: '0',
      borderRadius: '1em',
      width: '80%',
      height: '20vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      backgroundColor: 'rgba(24,24,24,1)',
    },
    hrCommentBreak: {
      width: '100%',
      height: '1px',
      background:
        'linear-gradient(90deg, rgba(255, 255, 255, 0) 19.83%, #FFFFFF 50.48%, rgba(255, 255, 255, 0) 80.41%)',
      opacity: '0.6',
      flex: 'none !important',
      alignSelf: 'stretch',
      flexGrow: '0',
    },
    label: {
      display: 'block',
      position: 'relative',
      padding: '0.3125em',
      cursor: 'pointer',
    },
    input: {
      position: 'absolute',
      opacity: 0,
      cursor: 'pointer',
    },
    span: {
      position: 'relative',
      display: 'inline-block',
      width: '0.5em',
      height: '0.5em',
      backgroundColor: '#ffffff',
      borderRadius: '50%',
      border: '0.25em solid white',
    },
    selected: {
      border: '0.25em solid var(--primary-green)',
    },
    rootContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'var(--background-primary)',
      width: '100%',
      zIndex: '-10',
      overflowX: 'hidden',
      position: 'absolute',
    },
    mainPageContainer: {
      width: '86%',
      overflow: 'hidden',
      minHeight: '600px',
      // border: '0.0625em solid white',
    },

    ellipse33: {
      width: '28em',
      position: 'absolute',
      height: '33em',
      right: 'calc(20vw)',
      top: 'calc(15vh)',
      background:
        'linear-gradient(98.24deg, rgb(0, 104, 255) 0%, rgb(255, 84, 0) 34.51%, rgb(236, 0, 130) 57.27%, rgb(178, 239, 1) 100%)',
      opacity: '0.4',
      filter: 'blur(90px)',
      transform: ' rotate(55.83deg)',
      pointerEvents: 'none',
    },

    ellipse34: {
      position: 'absolute',
      width: '20em',
      height: '15em',
      left: 'calc(30%)',
      top: 'calc(20% + 100vh)',
      background: 'linear-gradient(98.24deg, #6DDCFF 0%, #7F60F9 100%)',
      opacity: '0.5',
      filter: 'blur(13.125em)',
      transform: 'rotate(190.83deg)',
      pointerEvents: 'none',
    },
    ellipse35: {
      position: 'absolute',
      width: '20em',
      height: '25em',
      left: 'calc(-5%)',
      top: ' 125vh',
      background:
        'linear-gradient(98.24deg, #FF5400 0%, #0068FF 48.77%, #B2EF01 100%)',
      opacity: '0.5',
      filter: 'blur(10em)',
      transform: 'rotate(82.83deg)',
      pointerEvents: 'none',
    },
    ellipse36: {
      position: 'absolute',
      width: '20em',
      height: '25em',
      bottom: '250px',
      background:
        'linear-gradient(98.24deg, #0068FF 0%, #FF5400 34.51%, #EC0082 57.27%, #B2EF01 100%)',
      opacity: '0.4',
      filter: 'blur(120px)',
      transform: 'rotate(55.83deg)',
      pointerEvents: 'none',
    },
  };

  // Mobile based constants
  const [isMobile, setIsMobile] = useState(false);

  function scrollToHash() {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  // Call the function when the page loads
  window.onload = scrollToHash;

  useEffect(() => {
    console.log(window.innerWidth);

    const updateIsMobile = () => {
      setIsMobile(window.innerWidth < window.innerHeight); // Adjust threshold as needed
    };

    updateIsMobile(); // Check initially
    window.addEventListener('resize', updateIsMobile);

    return () => {
      window.removeEventListener('resize', updateIsMobile);
    };
  }, []);
  useDynamicText();

  const [shouldRenderSubmitButton, setShouldRenderSubmitButton] =
    useState(true);

  const handleSubmit = async (e) => {
    window.location.href = 'https://t.me/';
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return isMobile ? (
    <MobileView />
  ) : (
    <main style={styles.rootContainer}>
      <div style={styles.ellipse35}></div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'fixed',
          top: '0px',
          width: 'calc(100vw - 4em)',
          padding: '0.5em 2em',
          zIndex: '11',
          background: '#000',
          height: '85px',
        }}
      >
        <img
          src={logo}
          alt="futy_logo"
          width={140}
          style={{
            position: 'relative',
            left: '4vw',
          }}
        />
        <JoinNowButton
          handleJoinTelegram={handleJoinTelegram}
          className="w-[300px] h-[90%]"
        />
      </div>
      <div style={styles.ellipse33}></div>
      <div
        className="container-fluid"
        style={{ ...styles.mainPageContainer, minHeight: '800px' }}
        id="registerSubPageContainer"
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'auto auto',
            justifyContent: 'space-between',
            position: 'relative',
            top: '28.5vh',
            color: 'white',
            width: '100%',
            minHeight: '500px',
          }}
        >
          <div>
            <span
              style={{
                display: 'block',
                marginBottom: '0.625em', // 0.625em -> 8 - 12 - 16 px for the root em sizes
                fontFamily: "'RocGrotesk', sans-serif",
                fontWeight: '700',
                fontSize: '3em',
                color: '#ffffff',
              }}
            >
              Where Football Fans{' '}
              <b
                style={{
                  fontSize: '1.1em',
                  color: 'var(--primary-green)',
                  marginBottom: '2em',
                }}
              >
                Play, Compete & Earn
              </b>
            </span>
            <span
              style={{
                display: 'block',
                marginBottom: '0',
                fontFamily: "'Inter', sans-serif",
                fontWeight: 400,
                fontSize: '1.3em',
                lineHeight: '1.75em',
                color: '#A1A1AA',
              }}
            >
              <span>Free to </span>
              <span
                class="dynamic-text"
                style={{ position: 'relative' }}
              ></span>
            </span>

            <span
              style={{
                marginBottom: '0.625em',
                display: 'inline-block',
                background: `-webkit-linear-gradient(left, #2196F3, #4CAF50)`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontFamily: "'Inter', sans-serif",
                fontWeight: 400,
                fontSize: '1.3em',
                lineHeight: '1.75em',
              }}
            >
              Play Directly on Telegram - Earn FUTY Credits and get ready for
              the $FUTY Airdrop
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              position: 'relative',
              top: '-8%',
              right: '0',
              width: '35vw',
              height: '44vh',
              backgroundColor: 'rgba(24, 24, 27, 0.7)',
              minHeight: '425px',
            }}
            className="rounded-[40px]"
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '83%',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '0.5em',
                marginBottom: '1em',
              }}
            >
              <span
                style={{
                  fontFamily: "'RocGrotesk', sans-serif",
                  fontWeight: 600,
                  fontSize: '2.4em',
                  color: '#fff',
                }}
              >
                Coming Soon
              </span>
              <span className="font-inter font-medium text-white text-3xl leading-6 tracking-wider text-center">
                Join Our Telegram Now
              </span>
            </div>

            <JoinNowButton
              handleClick={handleJoinTelegram}
              className="w-[70%]"
            />
          </div>
        </div>
      </div>
      <img
        src={footballWithLine}
        alt="footballWithLine"
        style={{
          width: '100vw',
        }}
      />

      {/* Contact Us section start */}
      <div
        style={{
          ...styles.mainPageContainer,
          width: '65%',
          height: '90vh',
          minHeight: '720px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}
        id="contactUs"
      >
        {!isMessageSent ? (
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              width: '50vw',
              backgroundColor: 'rgba(24, 24, 27, 0.7)',
              color: '#fff',
              marginBottom: '1em',
            }}
            className="rounded-[40px]"
          >
            <span
              style={{
                width: '83%',
                height: '12%',
                fontFamily: "'RocGrotesk', sans-serif",
                fontWeight: 600,
                fontSize: '2em',
                textAlign: 'center',
                padding: '1em 0em',
              }}
            >
              Contact Us
            </span>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '90%',
                height: '41%',
              }}
            >
              <label
                htmlFor="email"
                style={{
                  fontSize: '1em',
                  fontFamily: "'Inter', sans-serif",
                  fontWeight: '500',
                  marginBottom: '0.75em',
                }}
              >
                Email
              </label>
              <input
                type="email"
                id="contactUsFormEmail"
                name="contactUsFormEmail"
                value={contactUsFormEmail}
                onChange={(e) => setContactUsFormEmail(e.target.value)}
                style={{
                  border: '0.1em rgba(255,255,255,0.2) solid',
                  padding: '0.5em 0em 0.5em 1.25em',
                  borderWidth: '0',
                  backgroundColor: 'rgba(255,255,255,0.05)',
                  color: 'white',
                  borderRadius: '0.5em',
                  marginBottom: '2.5em',
                  fontFamily: "'Inter',sans-serif",
                  fontWeight: 'normal',
                  fontSize: '1em',
                }}
                placeholder="you@somemail.com"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" // Regular expression for email validation
                title="Enter a valid email address"
                required
              ></input>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '90%',
                height: '41%',
              }}
            >
              <label
                htmlFor="name"
                style={{
                  fontSize: '1em',
                  fontFamily: "'Inter', sans-serif",
                  fontWeight: '500',
                  marginBottom: '0.75em',
                }}
              >
                Name
              </label>
              <input
                type="text"
                id="contactUsFormName"
                name="contactUsFormName"
                value={contactUsFormName}
                onChange={(e) => setContactUsFormName(e.target.value)}
                style={{
                  border: '0.1em rgba(255,255,255,0.2) solid',
                  borderWidth: '0',
                  backgroundColor: 'rgba(255,255,255,0.05)',
                  color: 'white',
                  borderRadius: '0.5em',
                  height: '33%',
                  marginBottom: '2.5em',
                  padding: '0.5em 0em 0.5em 1.25em',
                  fontFamily: "'Inter',sans-serif",
                  fontWeight: 'normal',
                  fontSize: '1em',
                }}
                placeholder="Your Name"
                required
              ></input>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '90%',
                height: '41%',
              }}
            >
              <label
                htmlFor="content"
                style={{
                  fontSize: '1em',
                  fontFamily: "'Inter', sans-serif",
                  fontWeight: '500',
                  marginBottom: '0.75em',
                }}
              >
                What can we help you with ?
              </label>
              <textarea
                type="text"
                rows={4}
                id="contactUsFormContent"
                name="contactUsFormContent"
                value={contactUsFormContent}
                onChange={(e) => setContactUsFormContent(e.target.value)}
                style={{
                  border: '0.1em rgba(255,255,255,0.2) solid',
                  backgroundColor: 'rgba(255,255,255,0.05)',
                  color: 'white',
                  borderRadius: '0.5em',
                  height: '33%',
                  marginBottom: '1em',
                  padding: '0.5em 0em 0.5em 1.25em',
                  fontFamily: "'Inter',sans-serif",
                  fontWeight: 'normal',
                  fontSize: '1em',
                }}
                placeholder="I want help with... (or) Feedback: ..."
                required
              ></textarea>
            </div>
            <button
              onClick={handleContactUsSubmit}
              style={{
                width: '90%',
                height: '12%',
                borderWidth: '0',
                backgroundColor: 'var(--primary-green)',
                fontFamily: "'RocGrotesk', sans-serif",
                fontWeight: '600',
                fontSize: '1em',
                color: '#fff',
                textAlign: 'center',
                cursor: 'pointer',
                padding: '1em 0em',
                marginBottom: '1em',
                zIndex: 2,
              }}
              className="rounded-full"
            >
              <span>SEND MESSAGE</span>
            </button>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                position: 'relative',
                borderRadius: '2em',
                padding: '1em 0em',
                backgroundColor: 'rgba(24, 24, 27, 0.7)',
                color: '#fff',
                width: '73vh',
              }}
            >
              <img
                src={tickPng}
                alt="successPng"
                style={{
                  width: '7em',
                  height: '7em',
                  paddingBottom: '0.5em',
                }}
              />
              <span
                style={{
                  fontFamily: "'RocGrotesk', sans-serif",
                  fontWeight: 600,
                  fontSize: '2.5em',
                  paddingBottom: '0.5em',
                }}
              >
                Message Sent
              </span>
            </div>
          </div>
        )}

        <div style={styles.contactUsLineBreak}></div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '40% 30% 30%',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              marginTop: '1.5em',
            }}
          >
            <span
              style={{
                color: 'rgba(255,255,255,0.6)',
                fontFamily: "'RocGrotesk', sans-serif",
                fontWeight: 600,
                fontSize: '1.5em',
                margin: '0em',
              }}
            >
              Our Location
            </span>
            <span
              style={{
                fontFamily: "'Inter',sans-serif",
                fontWeight: 'normal',
                fontSize: '1.25em',
                color: 'rgba(255,255,255,1)',
                margin: '0.5em 0em 1.5em 0em',
                textAlign: 'center',
              }}
            >
              Pachisi Ltd <br />
              20 Wenlock Road, London, N1 7GU
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <span
              style={{
                color: 'rgba(255,255,255,0.6)',
                fontFamily: "'RocGrotesk', sans-serif",
                fontWeight: 600,
                fontSize: '1.5em',
                margin: '0em',
              }}
            >
              Our Socials
            </span>
            <div
              style={{
                display: 'flex',
                width: '80%',
                justifyContent: 'space-evenly',
              }}
            >
              <a href="https://www.instagram.com/futy_uk/">
                <img
                  src={instagramPng}
                  alt="instaLogo"
                />
              </a>
              <a href="https://www.tiktok.com/@futy_uk">
                <img
                  src={tiktokPng}
                  alt="tiktokLogo"
                />
              </a>
              <a href="https://www.linkedin.com/company/futyyyuk/?originalSubdomain=uk">
                <img
                  src={linkedInWhitePng}
                  alt="linkedInLogo"
                />
              </a>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <span
              style={{
                color: 'rgba(255,255,255,0.6)',
                fontFamily: "'RocGrotesk', sans-serif",
                fontWeight: 600,
                fontSize: '1.5em',
                margin: '0em',
              }}
            >
              Legal
            </span>
            <div
              style={{
                position: 'relative',
                top: '-5%',
                display: 'flex',
                flexDirection: 'column',
                width: '80%',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                gap: '2px',
              }}
            >
              <a
                href="/termsAndConditions"
                style={{
                  fontFamily: "'Inter',sans-serif",
                  fontWeight: 'normal',
                  fontSize: '1.15em',
                  color: 'rgba(255,255,255,1)',
                  textAlign: 'center',
                }}
              >
                Terms and Conditions
              </a>
              <a
                href="/privacyPolicy"
                style={{
                  fontFamily: "'Inter',sans-serif",
                  fontWeight: 'normal',
                  fontSize: '1.15em',
                  color: 'rgba(255,255,255,1)',
                  textAlign: 'center',
                }}
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </div>
      <div style={styles.ellipse36}></div>
      {/* Contact Us section end */}
    </main>
  );
}
