import { useState, useEffect } from 'react';
import logo from './images/FutyLogoWhite.png';
import footballWithLine from './images/FootballWithHalfLine.png';
import instagramPng from './images/instagram.png';

import tiktokPng from './images/tiktok.png';
import linkedInWhitePng from './images/linkedinWhite.png';
import tickPng from './images/tickGreen.png';
import useDynamicText from './hooks/useDynamicText';
import './App.css';
import AppScreensImageCarousel from './pages/components/AppScreensImageCarousel';
import JoinNowButton from './pages/components/JoinNowButton';
import { handleJoinTelegram } from './utils/constants';

// const viewPortHeight = window.innerHeight;
const viewPortWidth = window.innerWidth;
let variant = '0';
const getQueryParam = (name) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name);
};

variant = getQueryParam('var');
console.log('Value of "variant" query parameter:', variant);

// const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export default function MobileView() {
  const [userAgent] = useState(navigator.userAgent);
  const [platform, setPlatform] = useState('');
  useEffect(() => {
    if (userAgent.match(/Android/i)) {
      setPlatform('Android');
    } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
      setPlatform('iOS');
    } else {
      setPlatform('Unknown');
    }
    console.log(platform);
  }, []);
  useDynamicText();
  const handleContactUsSubmit = async (e) => {
    e.preventDefault();
    try {
      const queryParams = new URLSearchParams(window.location.search);
      let source = queryParams.get('source')
        ? queryParams.get('source')
        : 'unknown';

      let formData = {
        email: contactUsFormEmail,
        name: contactUsFormName,
        message: contactUsFormContent,
        source: source,
      };
      if (!formData.email || !formData.name || !formData.message) {
        alert('email, name, reason to contact are all mandatory');
        return;
      }
      console.log(formData);
      setIsMessageSent(true);
      const url = 'https://api.futygame.uk/v0/contactUs';
      // const url = 'http://localhost:9000/v0/contactUs';

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const responseData = await response.json(); // Extract JSON data from response

      console.log(responseData); // Output the response data to console

      setTimeout(() => {
        setContactUsFormContent('');
        setContactUsFormEmail('');
        setContactUsFormName('');
        setIsMessageSent(false);
      }, 180000);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const [contactUsFormEmail, setContactUsFormEmail] = useState('');
  const [contactUsFormName, setContactUsFormName] = useState('');
  const [contactUsFormContent, setContactUsFormContent] = useState('');
  const [isMessageSent, setIsMessageSent] = useState(false);

  const mobileViewStyles = {
    contactUsLineBreak: {
      width: '100%',
      height: '0.1em',

      background: '#FFFFFF',
      opacity: '0.2',
    },
    commentBox: {
      color: '#fff',
      border: '0',
      borderRadius: '1em',
      width: '60vw',
      height: '60%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      backgroundColor: 'rgba(24,24,24,1)',
    },
    commentBoxNew: {
      color: '#fff',
      border: '0',
      borderRadius: '2em',
      width: '90%',
      height: '28%',
      minHeight: '105px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      backgroundColor: 'rgba(24,24,24,1)',
    },
    hrCommentBreak: {
      width: '100%',
      height: '1px',
      background:
        'linear-gradient(90deg, rgba(255, 255, 255, 0) 19.83%, #FFFFFF 50.48%, rgba(255, 255, 255, 0) 80.41%)',
      opacity: '0.6',
      flex: 'none !important',
      alignSelf: 'stretch',
      flexGrow: '0',
    },
    label: {
      display: 'block',
      position: 'relative',
      padding: '0.3125em',
      cursor: 'pointer',
    },
    input: {
      position: 'absolute',
      opacity: 0,
      cursor: 'pointer',
    },
    span: {
      position: 'relative',
      display: 'inline-block',
      width: '0.5em',
      height: '0.5em',
      backgroundColor: '#ffffff',
      borderRadius: '50%',
      border: '0.25em solid white',
    },
    selected: {
      border: '0.25em solid var(--primary-green)',
    },
    rootContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'var(--background-primary)',
      width: '100dvw',
      overflowX: 'hidden',
      position: 'relative',
    },
    mainPageContainer: {
      width: '90%',
      overflow: 'hidden',
      zIndex: 2,
    },

    ellipse33: {
      position: 'absolute',
      width: '50em',
      height: '40em',
      top: 'calc(425px)',
      background:
        'linear-gradient(98.24deg, #0068FF 0%, #FF5400 34.51%, #EC0082 57.27%, #B2EF01 100%)',
      opacity: '0.45',
      filter: 'blur(11em)',
      transform: 'rotate(145.83deg)',
      overflowX: 'hidden',
    },

    ellipse34: {
      position: 'absolute',
      width: '40em',
      height: '30em',
      left: 'calc(10%)',
      top: 'calc(1800px)',
      background: 'linear-gradient(98.24deg, #6DDCFF 0%, #7F60F9 100%)',
      opacity: '0.5',
      filter: 'blur(13em)',
      transform: 'rotate(190.83deg)',
      overflowX: 'hidden',
    },
    ellipse35: {
      position: 'absolute',
      width: '40em',
      height: '50em',
      left: 'calc(-5%)',
      top: 'calc(110vh)',
      background:
        'linear-gradient(98.24deg, #FF5400 0%, #0068FF 48.77%, #B2EF01 100%)',
      opacity: '0.4',
      filter: 'blur(12.5em)',
      transform: 'rotate(82.83deg)',
      overflowX: 'hidden',
    },
    ellipse36: {
      position: 'absolute',
      width: '50em',
      height: '40em',
      bottom: 'calc(45vh)',
      background:
        'linear-gradient(98.24deg, #0068FF 0%, #FF5400 34.51%, #EC0082 57.27%, #B2EF01 100%)',
      opacity: '0.45',
      filter: 'blur(11em)',
      transform: 'rotate(145.83deg)',
      overflowX: 'hidden',
    },
  };
  return (
    <main className="w-full h-auto absolute overflow-x-hidden bg-[var(--background-primary)] flex flex-col">
      <div style={mobileViewStyles.ellipse33}></div>
      <div style={mobileViewStyles.ellipse35}></div>
      <div style={mobileViewStyles.ellipse36}></div>
      <div className="flex w-full justify-around items-center h-[60px] top-0 fixed z-10 bg-black overflow-x-hidden">
        <img
          src={logo}
          className="w-1/4 h-auto relative"
          alt="futy_logo"
        />
        <JoinNowButton
          handleClick={handleJoinTelegram}
          className="w-[150px] relative"
        />
      </div>
      <div
        className="w-full top-[65px] relative px-8"
        id="landingPage"
      >
        <div className="flex flex-col justify-center items-center my-8 w-full h-full gap-2">
          <div
            style={{
              fontFamily: "'RocGrotesk', sans-serif",
            }}
            className="flex flex-col justify-center items-center my-8 w-full h-full gap-1"
          >
            <span className="font-700 text-[24px] text-center text-white">
              Where Football Fans
              <br />
              <b
                style={{
                  fontSize: '1.3em',
                  color: 'var(--primary-green)',
                }}
                className="drop-shadow-[0_1px_3px_rgba(0,154,87,0.8)]"
              >
                Play, Compete & Earn
              </b>
            </span>
            <span
              style={{
                display: 'block',
                fontFamily: "'Inter', sans-serif",
                fontWeight: 400,
                fontSize: '1.75em',
                lineHeight: '1.75em',
                color: '#A1A1AA',
              }}
            >
              Your{' '}
              <span
                class="dynamic-text"
                style={{ position: 'relative' }}
              ></span>
            </span>
            <span
              style={{
                background: `-webkit-linear-gradient(left, #0068FF, #B2EF01)`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontFamily: "'Inter', sans-serif",
                fontWeight: 600,
                fontSize: '1.7em',
                lineHeight: '1.75em',
                width: '60%',
              }}
              className="mb-8 text-center"
            >
              Play Directly on Telegram
            </span>
            <AppScreensImageCarousel />
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              alignSelf: 'center',
              alignItems: 'center',
              position: 'relative',
              borderRadius: '2em',
              backgroundColor: 'rgba(24, 24, 27, 0.7)',
            }}
            className="py-8 gap-8 w-[80%]"
          >
            <div className="flex flex-col items-center justify-center">
              <span
                style={{
                  fontFamily: "'RocGrotesk', sans-serif",
                  fontWeight: 600,
                  fontSize: '2.5em',
                  color: '#fff',
                  textAlign: 'center',
                  marginBottom: '0.2em',
                }}
              >
                Coming Soon
              </span>
              <span
                style={{
                  fontFamily: "'Inter', sans-serif",
                  fontWeight: 500,
                  fontSize: '1.5em',
                  color: '#fff',
                  letterSpacing: '0.05em',
                }}
              >
                Join Our Telegram For Updates
              </span>
            </div>
            <JoinNowButton
              handleClick={handleJoinTelegram}
              className="w-[150px]"
            />
          </div>
        </div>
      </div>
      <img
        src={footballWithLine}
        alt="footballWithLine"
        style={{
          marginTop: '1em',
          width: '100%',
          left: '0',
          height: viewPortWidth < 750 ? '75px' : '120px',
          objectFit: 'cover',
        }}
        className="top-[65px] relative"
      />

      {/* Contact Us section start */}
      <div className="relative top-[65px] py-4 flex flex-col justify-center items-center">
        {!isMessageSent ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              position: 'relative',
              width: '90%',
              minHeight: '38vh',
              borderRadius: '2em',
              padding: '1em 0em',
              backgroundColor: 'rgba(24, 24, 27, 0.7)',
              color: '#fff',
            }}
            className="my-4"
          >
            <span
              style={{
                textAlign: 'center',
                width: '90%',
                fontFamily: "'RocGrotesk', sans-serif",
                fontWeight: 600,
                fontSize: '3em',
                margin: '0.5em 0em 1em 0em',
              }}
            >
              Contact Us
            </span>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '90%',
                height: '41%',
              }}
            >
              <label
                htmlFor="email"
                style={{
                  fontSize: '1.5em',
                  fontFamily: "'Inter', sans-serif",
                  fontWeight: '500',
                  marginBottom: '0.75em',
                }}
              >
                Email
              </label>
              <input
                type="email"
                id="contactUsFormEmail"
                name="contactUsFormEmail"
                value={contactUsFormEmail}
                onChange={(e) => setContactUsFormEmail(e.target.value)}
                style={{
                  border: '0.1em rgba(255,255,255,0.2) solid',
                  backgroundColor: 'rgba(255,255,255,0.05)',
                  color: 'white',
                  borderRadius: '0.5em',
                  height: '33%',
                  marginBottom: '2.5em',

                  fontFamily: "'Inter',sans-serif",
                  fontWeight: 'normal',
                  fontSize: '1.75em',
                  padding: '0.75em 0em 0.75em 1.25em',
                }}
                placeholder="you@somemail.com"
                required
              ></input>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '90%',
                height: '41%',
              }}
            >
              <label
                htmlFor="name"
                style={{
                  fontSize: '1.5em',
                  fontFamily: "'Inter', sans-serif",
                  fontWeight: '500',
                  marginBottom: '0.75em',
                }}
              >
                Name
              </label>
              <input
                type="text"
                id="contactUsFormName"
                name="contactUsFormName"
                value={contactUsFormName}
                onChange={(e) => setContactUsFormName(e.target.value)}
                style={{
                  border: '0.1em rgba(255,255,255,0.2) solid',
                  backgroundColor: 'rgba(255,255,255,0.05)',
                  color: 'white',
                  borderRadius: '0.5em',
                  height: '33%',
                  marginBottom: '2.5em',

                  fontFamily: "'Inter',sans-serif",
                  fontWeight: 'normal',
                  fontSize: '1.75em',
                  padding: '0.75em 0em 0.75em 1.25em',
                }}
                placeholder="Your Name"
                required
              ></input>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '90%',
                height: '41%',
              }}
            >
              <label
                htmlFor="content"
                style={{
                  fontSize: '1.5em',
                  fontFamily: "'Inter', sans-serif",
                  fontWeight: '500',
                  marginBottom: '0.75em',
                }}
              >
                What can we help you with ?
              </label>
              <textarea
                type="text"
                rows={5}
                id="contactUsFormContent"
                name="contactUsFormContent"
                value={contactUsFormContent}
                onChange={(e) => setContactUsFormContent(e.target.value)}
                style={{
                  backgroundColor: 'rgba(255,255,255,0.05)',
                  color: 'white',
                  borderRadius: '0.5em',
                  marginBottom: '1.5em',
                  border: '0.1em rgba(255,255,255,0.2) solid',
                  fontFamily: "'Inter',sans-serif",
                  fontWeight: 'normal',
                  fontSize: '1.75em',
                  padding: '0.75em 0em 0.75em 1.25em',
                }}
                placeholder="I want help with... / Feedback::..."
                required
              ></textarea>
            </div>
            <button
              onClick={handleContactUsSubmit}
              style={{
                width: '90%',
                height: '12%',
                backgroundColor: 'var(--primary-green)',
                fontFamily: "'RocGrotesk', sans-serif",
                fontWeight: '600',
                fontSize: '1.75em',
                color: '#fff',
                textAlign: 'center',
                cursor: 'pointer',
                letterSpacing: '2px',
                padding: '0.5em 0em',
              }}
              className="rounded-full"
            >
              <span>SEND MESSAGE</span>
            </button>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              minHeight: '40vh',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                position: 'relative',
                width: '100%',
                maxHeight: '25vh',
                borderRadius: '2em',
                padding: '1em 0em',
                backgroundColor: 'rgba(24, 24, 27, 0.7)',
                color: '#fff',
              }}
            >
              <img
                src={tickPng}
                alt="successPng"
                style={{
                  margin: '2em 0em 1em 0em',
                  width: '10em',
                  height: '10em',
                }}
              />
              <span
                style={{
                  fontFamily: "'RocGrotesk', sans-serif",
                  fontWeight: 600,
                  fontSize: '3.5em',
                  margin: '0.5em 0em 1em 0em',
                }}
              >
                Message Sent
              </span>
            </div>
          </div>
        )}

        <div style={mobileViewStyles.contactUsLineBreak}></div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            marginTop: '1.5em',
          }}
        >
          <span
            style={{
              color: 'rgba(255,255,255,0.6)',
              fontFamily: "'RocGrotesk', sans-serif",
              fontWeight: 600,
              fontSize: '2.2em',
              margin: '0em',
            }}
          >
            Our Location
          </span>
          <span
            style={{
              fontFamily: "'Inter',sans-serif",
              fontWeight: 'normal',
              fontSize: '1.75em',
              color: 'rgba(255,255,255,1)',
              margin: '0.5em 0em 1.5em 0em',
              textAlign: 'center',
            }}
          >
            Pachisi Ltd <br />
            20 Wenlock Road, London, N1 7GU
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <span
            style={{
              color: 'rgba(255,255,255,0.6)',
              fontFamily: "'RocGrotesk', sans-serif",
              fontWeight: 600,
              fontSize: '2.2em',
              margin: '0em',
            }}
          >
            Our Socials
          </span>
          <div
            style={{
              display: 'flex',
              width: '80%',
              justifyContent: 'space-evenly',
              margin: '1em 0em',
            }}
          >
            <a href="https://www.instagram.com/futy_uk/">
              <img
                src={instagramPng}
                alt="instaLogo"
              />
            </a>
            <a href="https://www.tiktok.com/@futy_uk">
              <img
                src={tiktokPng}
                alt="tiktokLogo"
              />
            </a>
            <a href="https://www.linkedin.com/company/futyyyuk/?originalSubdomain=uk">
              <img
                src={linkedInWhitePng}
                alt="linkedInLogo"
              />
            </a>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <span
            style={{
              color: 'rgba(255,255,255,0.6)',
              fontFamily: "'RocGrotesk', sans-serif",
              fontWeight: 600,
              fontSize: '2.2em',
              margin: '0em',
            }}
          >
            Legal
          </span>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '80%',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            <a
              href="/termsAndConditions"
              style={{
                fontFamily: "'Inter',sans-serif",
                fontWeight: 'normal',
                fontSize: '1.75em',
                color: 'rgba(255,255,255,1)',
                textAlign: 'center',
                marginTop: '8px',
              }}
            >
              Terms and Conditions
            </a>
            <a
              href="/privacyPolicy"
              style={{
                fontFamily: "'Inter',sans-serif",
                fontWeight: 'normal',
                fontSize: '1.75em',
                color: 'rgba(255,255,255,1)',
                textAlign: 'center',
              }}
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
      {/* Contact Us section end */}
    </main>
  );
}
