import telegramPng from '../../images/TelegramLogo.png';
import cn from '../../utils/clsx';

const JoinNowButton = ({ handleClick, className }) => {
  return (
    <button
      className={cn(
        'flex items-center gap-4 justify-center cursor-pointer py-4 px-6 bg-[var(--primary-green)] rounded-full shadow-[0_2px_5px_rgb(255,255,255,1)]',
        className
      )}
      onClick={handleClick}
    >
      <img
        src={telegramPng}
        alt="JoinTelegram"
        className="w-14 h-14 cursor-pointer"
      />
      <span
        className="text-white text-[16px]"
        style={{ fontFamily: "'RocGrotesk', sans-serif" }}
      >
        Join Now
      </span>
    </button>
  );
};

export default JoinNowButton;
