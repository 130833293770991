import React from 'react';
import Appscreen1 from '../../images/AppScreen1.png';
import Appscreen2 from '../../images/AppScreen2.png';
import Appscreen3 from '../../images/AppScreen3.png';
import Appscreen4 from '../../images/AppScreen4.png';
import Appscreen5 from '../../images/AppScreen5.png';
import Appscreen6 from '../../images/AppScreen6.png';
import Appscreen7 from '../../images/AppScreen7.png';

export default function AppScreensImageCarousel() {
  return (
    <>
      <div className="w-full overflow-x-scroll flex gap-[10px] py-4">
        {[
          Appscreen1,
          Appscreen2,
          Appscreen3,
          Appscreen4,
          Appscreen5,
          Appscreen6,
          Appscreen7,
        ].map((img, index) => (
          <img
            key={index}
            src={img}
            alt={`App_Screen_${index + 1}`}
            className="w-3/5 h-auto rounded-[22px] border-[1px] border-[var(--primary-green)] shadow-[0_3px_10px_rgba(0,154,87,0.8)]"
          />
        ))}
      </div>
      <span
        style={{ fontFamily: "'RocGrotesk', sans-serif", lineHeight: '1' }}
        className="w-full text-[var(--primary-green)] text-[12px] text-right py-4"
      >
        SWIPE --{'>'}
      </span>
    </>
  );
}
