export const REGEX = {
  UK_PHONE_NUMBER: /^\d{8,11}$/,
};

export const FAQ_QNA = [
  {
    question: 'What is FUTY?',
    answer: `FUTY is a distinctive fantasy football platform that lets you play single-game fantasy football.`,
  },
  {
    question: 'How do I play FUTY?',
    answer: `Playing FUTY is simple!  Download the iOS or Android app. Sign up using your phone number and you are good to go! Choose any of the games listed on the app and create your team for free.`,
  },
  {
    question: ' What are the rules of the game?',
    answer: `Select 7 players from the available pool, with a minimum of 3 players from each team. Players are categorized into defenders and attackers, with a minimum requirement of 2 defenders and 2 attackers in each team. Distribute 7 boosters among your selected players, with a maximum of 4 boosters per player. Each booster applied to a player increases their points by 25%.`,
  },
  {
    question: 'How do players earn points in FUTY?',
    answer: `Players earn points based on their real-life match performance, including goals, assists, tackles, clean sheets, and various other stats. For a detailed points breakdown, check the FUTY app.`,
  },
  {
    question: 'How are winners determined in FUTY?',
    answer: `Once the match concludes, player scores and leaderboards are posted. You can also monitor your team's points in real time! Users are ranked based on the total points accumulated by their selected team. Check the FUTY app for prize distribution details before joining any game.`,
  },
  {
    question: 'How are prizes awarded in FUTY?',
    answer: `Prizes are awarded in the form of FUTY coins. These coins can be used to redeem real life rewards from the Store on the FUTY app.`,
  },
  {
    question: 'How do I delete my FUTY account?',
    answer: `<a href="/deleteAccount" class="delete-account"> Click here</a> and follow the steps to delete your account.`,
  },
];

export const handleJoinTelegram = () => {
  console.log('Join Telegram');
  window.location.href = 'https://t.me/playfuty';
};
